.identicon {
  vertical-align: text-bottom;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 10%;
  margin-right: 5px;
  display: inline-block;
  box-shadow: inset 0 2px 2px #fff9, inset 0 -2px 6px #0000004d;
}

.btn-group:after, .cf:after, .fullpage-modal .btn-row:after, .modal .btn-row:after, .paging:after {
  clear: both;
}

dfn, span.label {
  font-style: italic;
}

audio, canvas, progress, sub, sup, video {
  vertical-align: baseline;
}

img, legend {
  border: 0;
}

pre, textarea {
  overflow: auto;
}

fieldset, hr {
  border: none;
}

.drop-shadow {
  box-shadow: 0 2px 4px #0003;
}

.drop-shadow-light {
  box-shadow: 0 2px 4px #0000001a;
}

.drop-shadow-lighter {
  box-shadow: 0 1px 4px #0000000d;
}

.drop-shadow-deep {
  box-shadow: 0 5px 15px #0003;
}

.cf:after, .cf:before {
  content: " ";
  display: table;
}

.btn, .caret, .caret-right, audio, canvas, progress, video {
  display: inline-block;
}

html {
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  font-family: sans-serif;
}

article, aside, details, figcaption, figure, footer, header, hgroup, main, menu, nav, section, summary {
  display: block;
}

audio:not([controls]) {
  height: 0;
  display: none;
}

[hidden], template {
  display: none;
}

a {
  background-color: #0000;
}

a:active, a:hover {
  outline: 0;
}

abbr[title] {
  border-bottom: 1px dotted;
}

b, optgroup, strong {
  font-weight: 700;
}

h1 {
  margin: .67em 0;
}

mark {
  color: #000;
  background: #ff0;
}

small {
  font-size: 80%;
}

sub, sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
}

.btn, .caret {
  vertical-align: middle;
  position: relative;
}

sup {
  top: -.5em;
}

sub {
  bottom: -.25em;
}

svg:not(:root) {
  overflow: hidden;
}

figure {
  margin: 1em 40px;
}

hr {
  box-sizing: content-box;
}

code, kbd, pre, samp {
  font-family: monospace;
  font-size: 1em;
}

button, input, optgroup, select, textarea {
  color: inherit;
  font: inherit;
  margin: 0;
}

.btn.btn-spinner.loading-done:before, .petalicon {
  font-variant: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  speak: none;
  font-family: petalicon;
  font-style: normal;
  font-weight: 400;
}

button {
  overflow: visible;
}

button, select {
  text-transform: none;
}

button, html input[type="button"], input[type="reset"], input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer;
}

button[disabled], html input[disabled] {
  cursor: default;
}

:is(button::-moz-focus-inner, input::-moz-focus-inner) {
  border: 0;
  padding: 0;
}

input {
  line-height: normal;
}

input[type="checkbox"], input[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

:is(input[type="number"]::-webkit-inner-spin-button, input[type="number"]::-webkit-outer-spin-button) {
  -webkit-appearance: none;
  height: auto;
}

:is(input[type="search"]::-webkit-search-cancel-button, input[type="search"]::-webkit-search-decoration) {
  -webkit-appearance: none;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

td, th {
  padding: 0;
}

@font-face {
  font-family: petalicon;
  src: url("petalicon.a6bce733.eot");
  src: url("petalicon.a6bce733.eot#iefix20170105") format("embedded-opentype"), url("petalicon.d600acb6.woff2") format("woff2"), url("petalicon.7ef82cb0.woff") format("woff"), url("petalicon.c98cf298.ttf") format("truetype"), url("petalicon.5fbc5e29.svg#petalicon") format("svg");
  font-weight: 400;
  font-style: normal;
}

.petalicon {
  text-transform: none;
  font-size: 16px;
}

.petalicon.x2 {
  font-size: 32px;
}

.petalicon-arrow-horizontal:before {
  content: "";
}

.petalicon-arrow-vertical:before {
  content: "";
}

.petalicon-check-round-filled:before {
  content: "";
}

.petalicon-cross-round-filled:before {
  content: "";
}

.petalicon-fit-horizontal:before {
  content: "";
}

.petalicon-fit-vertical:before {
  content: "";
}

.petalicon-rotate-ccw:before {
  content: "";
}

.petalicon-rotate-cw:before {
  content: "";
}

.petalicon-undo:before {
  content: "";
}

.petalicon-align-center:before {
  content: "";
}

.petalicon-align-left:before {
  content: "";
}

.petalicon-align-right:before {
  content: "";
}

.petalicon-align-text-center:before {
  content: "";
}

.petalicon-align-text-justify:before {
  content: "";
}

.petalicon-align-text-left:before {
  content: "";
}

.petalicon-align-text-right:before {
  content: "";
}

.petalicon-arrow-back:before {
  content: "";
}

.petalicon-arrow-down:before {
  content: "";
}

.petalicon-arrow-down-up:before {
  content: "";
}

.petalicon-arrow-forward:before {
  content: "";
}

.petalicon-arrow-left:before {
  content: "";
}

.petalicon-arrow-left-right:before {
  content: "";
}

.petalicon-arrow-right:before {
  content: "";
}

.petalicon-arrow-up:before {
  content: "";
}

.petalicon-bell:before {
  content: "";
}

.petalicon-brand:before {
  content: "";
}

.petalicon-brush:before {
  content: "";
}

.petalicon-bullhorn:before {
  content: "";
}

.petalicon-calendar:before {
  content: "";
}

.petalicon-camera:before {
  content: "";
}

.petalicon-car:before {
  content: "";
}

.petalicon-cart:before {
  content: "";
}

.petalicon-check:before {
  content: "";
}

.petalicon-check-round:before {
  content: "";
}

.petalicon-checklist:before {
  content: "";
}

.petalicon-chevron-down:before {
  content: "";
}

.paging .pg-block.prev .petalicon:before, .petalicon-chevron-left:before {
  content: "";
}

.petalicon-chevron-right:before {
  content: "";
}

.petalicon-chevron-small-bottom:before {
  content: "";
}

.petalicon-chevron-small-down:before {
  content: "";
}

.petalicon-chevron-small-left:before {
  content: "";
}

.petalicon-chevron-small-right:before {
  content: "";
}

.petalicon-chevron-small-up:before {
  content: "";
}

.petalicon-chevron-up:before {
  content: "";
}

.petalicon-circle:before {
  content: "";
}

.petalicon-clock:before {
  content: "";
}

.petalicon-cloud:before {
  content: "";
}

.petalicon-cloud-check:before {
  content: "";
}

.petalicon-cloud-cross:before {
  content: "";
}

.petalicon-cloud-upload:before {
  content: "";
}

.petalicon-code:before {
  content: "";
}

.petalicon-cog:before {
  content: "";
}

.petalicon-collection:before {
  content: "";
}

.petalicon-comment:before {
  content: "";
}

.petalicon-comments:before {
  content: "";
}

.petalicon-config:before {
  content: "";
}

.petalicon-coupon:before {
  content: "";
}

.petalicon-credit-card:before {
  content: "";
}

.petalicon-crop:before {
  content: "";
}

.petalicon-cross:before {
  content: "";
}

.petalicon-cross-round:before {
  content: "";
}

.petalicon-currency-dollar:before {
  content: "";
}

.petalicon-currency-won:before {
  content: "";
}

.petalicon-currency-yen:before {
  content: "";
}

.petalicon-customer-support:before {
  content: "";
}

.petalicon-dashboard:before {
  content: "";
}

.petalicon-device-desktop:before {
  content: "";
}

.petalicon-device-laptop:before {
  content: "";
}

.petalicon-device-mobile:before {
  content: "";
}

.petalicon-device-tablet:before {
  content: "";
}

.petalicon-device-tv:before {
  content: "";
}

.petalicon-document:before {
  content: "";
}

.petalicon-dollar-round:before {
  content: "";
}

.petalicon-door:before {
  content: "";
}

.petalicon-download:before {
  content: "";
}

.petalicon-draggable:before {
  content: "";
}

.petalicon-draggable-horizontal:before {
  content: "";
}

.petalicon-draggable-vertical:before {
  content: "";
}

.petalicon-edit-text:before {
  content: "";
}

.petalicon-ellipsis:before {
  content: "";
}

.petalicon-ellipsis-vertical:before {
  content: "";
}

.petalicon-embed:before {
  content: "";
}

.petalicon-exclamation:before {
  content: "";
}

.petalicon-expand:before {
  content: "";
}

.petalicon-eye:before {
  content: "";
}

.petalicon-eye-closed:before {
  content: "";
}

.petalicon-file:before {
  content: "";
}

.petalicon-file-copy:before {
  content: "";
}

.petalicon-file-paste:before {
  content: "";
}

.petalicon-filter:before {
  content: "";
}

.petalicon-flash:before {
  content: "";
}

.petalicon-folder:before {
  content: "";
}

.petalicon-font:before {
  content: "";
}

.petalicon-globe:before {
  content: "";
}

.petalicon-graph:before {
  content: "";
}

.petalicon-heart:before {
  content: "";
}

.petalicon-heart-outline:before {
  content: "";
}

.petalicon-help:before {
  content: "";
}

.petalicon-home:before {
  content: "";
}

.petalicon-import:before {
  content: "";
}

.petalicon-info:before {
  content: "";
}

.petalicon-item-photo:before {
  content: "";
}

.petalicon-item-text:before {
  content: "";
}

.petalicon-item-video:before {
  content: "";
}

.petalicon-key:before {
  content: "";
}

.petalicon-link:before {
  content: "";
}

.petalicon-link-round:before {
  content: "";
}

.petalicon-list:before {
  content: "";
}

.petalicon-location:before {
  content: "";
}

.petalicon-lock-locked:before {
  content: "";
}

.petalicon-lock-unlocked:before {
  content: "";
}

.petalicon-mail:before {
  content: "";
}

.petalicon-minus:before {
  content: "";
}

.petalicon-minus-round:before {
  content: "";
}

.petalicon-music:before {
  content: "";
}

.petalicon-new:before {
  content: "";
}

.petalicon-not-allowed:before {
  content: "";
}

.petalicon-notice:before {
  content: "";
}

.petalicon-outlink:before {
  content: "";
}

.petalicon-palette:before {
  content: "";
}

.petalicon-pencil:before {
  content: "";
}

.petalicon-phone:before {
  content: "";
}

.petalicon-photo:before {
  content: "";
}

.petalicon-placeholder-add:before {
  content: "";
}

.petalicon-play:before {
  content: "";
}

.petalicon-play-back:before {
  content: "";
}

.petalicon-play-ff:before {
  content: "";
}

.petalicon-play-pause:before {
  content: "";
}

.petalicon-play-round:before {
  content: "";
}

.petalicon-play-rw:before {
  content: "";
}

.petalicon-play-skip-next:before {
  content: "";
}

.petalicon-play-skip-prev:before {
  content: "";
}

.petalicon-play-stop:before {
  content: "";
}

.petalicon-plus:before {
  content: "";
}

.petalicon-plus-round:before {
  content: "";
}

.petalicon-publish:before {
  content: "";
}

.petalicon-refresh:before {
  content: "";
}

.petalicon-repeat:before {
  content: "";
}

.petalicon-replace:before {
  content: "";
}

.petalicon-rotate:before {
  content: "";
}

.petalicon-save:before {
  content: "";
}

.petalicon-scissor:before {
  content: "";
}

.petalicon-search:before {
  content: "";
}

.petalicon-send:before {
  content: "";
}

.petalicon-sfx:before {
  content: "";
}

.petalicon-shakr:before {
  content: "";
}

.petalicon-share:before {
  content: "";
}

.petalicon-share-alt:before {
  content: "";
}

.petalicon-shrink:before {
  content: "";
}

.petalicon-spinner:before {
  content: "";
}

.petalicon-stack:before {
  content: "";
}

.petalicon-star:before {
  content: "";
}

.petalicon-star-outline:before {
  content: "";
}

.petalicon-statistics:before {
  content: "";
}

.petalicon-statistics-alt:before {
  content: "";
}

.petalicon-stopwatch:before {
  content: "";
}

.petalicon-tag:before {
  content: "";
}

.petalicon-text:before {
  content: "";
}

.petalicon-tile:before {
  content: "";
}

.petalicon-tools:before {
  content: "";
}

.petalicon-translate:before {
  content: "";
}

.petalicon-translate-alt:before {
  content: "";
}

.petalicon-trash:before {
  content: "";
}

.petalicon-triangle-down:before {
  content: "";
}

.petalicon-triangle-left:before {
  content: "";
}

.petalicon-triangle-right:before {
  content: "";
}

.petalicon-triangle-up:before {
  content: "";
}

.petalicon-triangle-up-down:before {
  content: "";
}

.petalicon-upload:before {
  content: "";
}

.petalicon-upload-alt:before {
  content: "";
}

.petalicon-user:before {
  content: "";
}

.petalicon-user-round:before {
  content: "";
}

.petalicon-users:before {
  content: "";
}

.petalicon-video:before {
  content: "";
}

.petalicon-video-editor:before {
  content: "";
}

.petalicon-video-player:before {
  content: "";
}

.petalicon-volume-0:before {
  content: "";
}

.petalicon-volume-1:before {
  content: "";
}

.petalicon-volume-2:before {
  content: "";
}

.petalicon-volume-3:before {
  content: "";
}

.petalicon-volume-mute:before {
  content: "";
}

.petalicon-warning:before {
  content: "";
}

.petalicon-zoomin:before {
  content: "";
}

.petalicon-zoomout:before {
  content: "";
}

* {
  box-sizing: border-box;
}

html {
  width: auto;
  font-size: 16px;
}

body {
  color: #333;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif, Arial;
  font-size: 1rem;
  line-height: 1.2;
}

.container {
  width: 980px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
}

.container.limit-width {
  max-width: 980px;
}

.stretch {
  width: 100%;
}

.row {
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: stretch;
  margin-left: -15px;
  margin-right: -15px;
  display: flex;
}

.row.collapse-gutter > [class*=" col-"]:not(:first-of-type):not(:last-of-type), .row.collapse-gutter > [class^="col-"]:not(:first-of-type):not(:last-of-type) {
  padding-left: 7.5px;
  padding-right: 7.5px;
}

.row.collapse-gutter > [class*=" col-"]:first-of-type:not(:last-of-type), .row.collapse-gutter > [class^="col-"]:first-of-type:not(:last-of-type) {
  padding-right: 7.5px;
}

.row.collapse-gutter > [class*=" col-"]:last-of-type:not(:first-of-type), .row.collapse-gutter > [class^="col-"]:last-of-type:not(:first-of-type) {
  padding-left: 7.5px;
}

[class*=" col-"], [class^="col-"] {
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
}

[class*=" col-"] .stretch, [class^="col-"] .stretch {
  flex-wrap: wrap;
  display: flex;
}

[class*=" col-"] .stretch > *, [class^="col-"] .stretch > * {
  width: 100%;
}

.col-1 {
  flex-basis: 100%;
  width: 100%;
  max-width: 100%;
}

.col-2 {
  flex-basis: 50%;
  width: 50%;
  max-width: 50%;
}

.col-3 {
  flex-basis: 33.3333%;
  width: 33.3333%;
  max-width: 33.3333%;
}

.col-3.colspan-2 {
  flex-basis: 66.6667%;
  width: 66.6667%;
  max-width: 66.6667%;
}

.col-4 {
  flex-basis: 25%;
  width: 25%;
  max-width: 25%;
}

.col-4.colspan-2 {
  flex-basis: 50%;
  width: 50%;
  max-width: 50%;
}

.col-4.colspan-3 {
  flex-basis: 75%;
  width: 75%;
  max-width: 75%;
}

.col-5 {
  flex-basis: 20%;
  width: 20%;
  max-width: 20%;
}

.col-5.colspan-2 {
  flex-basis: 40%;
  width: 40%;
  max-width: 40%;
}

.col-5.colspan-3 {
  flex-basis: 60%;
  width: 60%;
  max-width: 60%;
}

.col-5.colspan-4 {
  flex-basis: 80%;
  width: 80%;
  max-width: 80%;
}

.m-5 {
  margin: 5px;
}

.m-10 {
  margin: 10px;
}

.m-15 {
  margin: 15px;
}

.m-20 {
  margin: 20px;
}

.mh-5 {
  margin-left: 5px;
  margin-right: 5px;
}

.mh-10 {
  margin-left: 10px;
  margin-right: 10px;
}

.mh-15 {
  margin-left: 15px;
  margin-right: 15px;
}

.mh-20 {
  margin-left: 20px;
  margin-right: 20px;
}

.mv-5 {
  margin-top: 5px;
  margin-bottom: 5px;
}

.mv-10 {
  margin-top: 10px;
  margin-bottom: 10px;
}

.mv-15 {
  margin-top: 15px;
  margin-bottom: 15px;
}

.mv-20 {
  margin-top: 20px;
  margin-bottom: 20px;
}

.mt-5 {
  margin-top: 5px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-15 {
  margin-top: 15px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-50 {
  margin-top: 50px;
}

.mb-5 {
  margin-bottom: 5px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-15 {
  margin-bottom: 15px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-50 {
  margin-bottom: 50px;
}

.btn, pre {
  margin: 0;
}

.p-5 {
  padding: 5px;
}

.p-10 {
  padding: 10px;
}

.p-15 {
  padding: 15px;
}

.p-20 {
  padding: 20px;
}

.ph-5 {
  padding-left: 5px;
  padding-right: 5px;
}

.ph-10 {
  padding-left: 10px;
  padding-right: 10px;
}

.ph-15 {
  padding-left: 15px;
  padding-right: 15px;
}

.ph-20, blockquote {
  padding-left: 20px;
}

.ph-20 {
  padding-right: 20px;
}

.pv-5 {
  padding-top: 5px;
  padding-bottom: 5px;
}

.pv-10 {
  padding-top: 10px;
  padding-bottom: 10px;
}

.pv-15 {
  padding-top: 15px;
  padding-bottom: 15px;
}

.pv-20 {
  padding-top: 20px;
  padding-bottom: 20px;
}

.pt-5 {
  padding-top: 5px;
}

.pt-10 {
  padding-top: 10px;
}

.pt-15 {
  padding-top: 15px;
}

.pt-20 {
  padding-top: 20px;
}

.pt-50 {
  padding-top: 50px;
}

.pb-5 {
  padding-bottom: 5px;
}

.pb-10 {
  padding-bottom: 10px;
}

.pb-15 {
  padding-bottom: 15px;
}

.pb-20 {
  padding-bottom: 20px;
}

.pb-50 {
  padding-bottom: 50px;
}

.text-red {
  color: #f05a67;
}

.text-red-l1 {
  color: #f48791;
}

.text-red-l2 {
  color: #f69ca4;
}

.text-red-l3 {
  color: #f8b3b9;
}

.text-red-l4 {
  color: #fbd1d5;
}

.text-red-l5 {
  color: #fde7e9;
}

.text-red-l6 {
  color: #fef5f6;
}

.text-red-d1 {
  color: #d44f5b;
}

.text-red-d2 {
  color: #b7444e;
}

.text-red-d3 {
  color: #92373f;
}

.text-red-d4 {
  color: #732b31;
}

.text-red-d5 {
  color: #5b2227;
}

.text-red-d6 {
  color: #481b1f;
}

.bg-red {
  background-color: #f05a67;
}

.bg-red-l1 {
  background-color: #f48791;
}

.bg-red-l2 {
  background-color: #f69ca4;
}

.bg-red-l3 {
  background-color: #f8b3b9;
}

.bg-red-l4 {
  background-color: #fbd1d5;
}

.bg-red-l5 {
  background-color: #fde7e9;
}

.bg-red-l6 {
  background-color: #fef5f6;
}

.bg-red-d1 {
  background-color: #d44f5b;
}

.bg-red-d2 {
  background-color: #b7444e;
}

.bg-red-d3 {
  background-color: #92373f;
}

.bg-red-d4 {
  background-color: #732b31;
}

.bg-red-d5 {
  background-color: #5b2227;
}

.bg-red-d6 {
  background-color: #481b1f;
}

.text-green {
  color: #b2cf65;
}

.text-green-l1 {
  color: #c7dc8f;
}

.text-green-l2 {
  color: #d1e2a3;
}

.text-green-l3 {
  color: #dbe9b8;
}

.text-green-l4 {
  color: #eaf2d4;
}

.text-green-l5 {
  color: #f4f8e9;
}

.text-green-l6 {
  color: #fafcf6;
}

.text-green-d1 {
  color: #9db759;
}

.text-green-d2 {
  color: #879d4d;
}

.text-green-d3 {
  color: #6c7e3d;
}

.text-green-d4 {
  color: #556330;
}

.text-green-d5 {
  color: #444f26;
}

.text-green-d6 {
  color: #353e1e;
}

.bg-green {
  background-color: #b2cf65;
}

.bg-green-l1 {
  background-color: #c7dc8f;
}

.bg-green-l2 {
  background-color: #d1e2a3;
}

.bg-green-l3 {
  background-color: #dbe9b8;
}

.bg-green-l4 {
  background-color: #eaf2d4;
}

.bg-green-l5 {
  background-color: #f4f8e9;
}

.bg-green-l6 {
  background-color: #fafcf6;
}

.bg-green-d1 {
  background-color: #9db759;
}

.bg-green-d2 {
  background-color: #879d4d;
}

.bg-green-d3 {
  background-color: #6c7e3d;
}

.bg-green-d4 {
  background-color: #556330;
}

.bg-green-d5 {
  background-color: #444f26;
}

.bg-green-d6 {
  background-color: #353e1e;
}

.text-blue {
  color: #30a4dc;
}

.text-blue-l1 {
  color: #69bde6;
}

.text-blue-l2 {
  color: #83c8ea;
}

.text-blue-l3 {
  color: #9fd5ef;
}

.text-blue-l4 {
  color: #c5e6f5;
}

.text-blue-l5 {
  color: #e1f2fa;
}

.text-blue-l6 {
  color: #f3fafd;
}

.text-blue-d1 {
  color: #2a91c2;
}

.text-blue-d2 {
  color: #257da7;
}

.text-blue-d3 {
  color: #1d6486;
}

.text-blue-d4 {
  color: #174e69;
}

.text-blue-d5 {
  color: #123e54;
}

.text-blue-d6 {
  color: #0e3142;
}

.bg-blue {
  background-color: #30a4dc;
}

.bg-blue-l1 {
  background-color: #69bde6;
}

.bg-blue-l2 {
  background-color: #83c8ea;
}

.bg-blue-l3 {
  background-color: #9fd5ef;
}

.bg-blue-l4 {
  background-color: #c5e6f5;
}

.bg-blue-l5 {
  background-color: #e1f2fa;
}

.bg-blue-l6 {
  background-color: #f3fafd;
}

.bg-blue-d1 {
  background-color: #2a91c2;
}

.bg-blue-d2 {
  background-color: #257da7;
}

.bg-blue-d3 {
  background-color: #1d6486;
}

.bg-blue-d4 {
  background-color: #174e69;
}

.bg-blue-d5 {
  background-color: #123e54;
}

.bg-blue-d6 {
  background-color: #0e3142;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: .5em;
}

h1 small, h2 small, h3 small, h4 small, h5 small, h6 small {
  color: #999;
  text-transform: none;
  letter-spacing: 0;
  font-size: .7em;
  font-weight: 400;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 900;
}

h1 {
  font-size: 3rem;
}

h2 {
  font-size: 2rem;
}

h3 {
  font-size: 1.5rem;
}

h4 {
  font-size: 1.25rem;
}

h4 small {
  font-size: .8em;
}

h5 {
  font-size: 1rem;
}

h5 small, h6 small {
  font-size: .9em;
}

h6 {
  font-size: .9rem;
}

.btn, code {
  font-size: .9em;
}

.uppercase, h3, h4, h5, h6 {
  text-transform: uppercase;
  letter-spacing: .1em;
}

.keepcase, code {
  letter-spacing: 0;
  text-transform: none;
}

p {
  margin-top: 0;
  margin-bottom: 1em;
  line-height: 1.5;
}

code {
  background-color: #eeeeee80;
  margin: 0 1px;
  padding: 1px 5px;
  font-family: SF Mono, Menlo, Monaco, Consolas, Courier New, monospace;
}

.dark code {
  background-color: #ffffff1a;
}

pre {
  background-color: #f0f0f0;
  padding: 0;
}

.dark pre {
  background-color: #ffffff1a;
}

pre code {
  background-color: #f0f0f0;
  padding: 15px;
}

.dark pre code {
  background-color: #ffffff1a;
}

blockquote {
  border-left: 2px solid #69bde6;
  margin-left: 20px;
}

ol, ul {
  margin: 0 0 1em;
  padding: 0 0 0 2em;
}

li {
  margin-top: 0;
  margin-bottom: .5em;
}

a {
  color: #f05a67;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

.btn, .form-label {
  text-transform: uppercase;
  font-weight: 900;
  text-decoration: none !important;
}

hr {
  background-color: #ddd;
  height: 1px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.dark hr {
  background-color: #333;
}

.caret {
  border-top: 4px solid #fff;
  border-left: 4px solid #0000;
  border-right: 4px solid #0000;
  width: 0;
  height: 0;
  transition: border-color .2s, transform .3s;
}

.caret-right {
  border-top: 4px solid #0000;
  border-bottom: 4px solid #0000;
  border-left: 4px solid #fff;
  width: 0;
  height: 0;
  position: relative;
  bottom: 2px;
}

.btn {
  text-align: center;
  white-space: nowrap;
  letter-spacing: 2px;
  cursor: pointer;
  background: #30a4dc;
  border: 2px solid #0000;
  border-radius: 0;
  padding: 8px 20px;
  line-height: 1.2;
  transition-property: background, border-color, color, box-shadow, opacity;
  transition-duration: .15s;
  outline: 0 !important;
}

.btn-group .btn + .btn, .btn-group .btn-group + .btn-group {
  margin-left: 5px;
}

.btn:hover {
  box-shadow: 0 2px 4px -2px #0003;
}

.btn:active, .btn:focus {
  box-shadow: 0 0 1px #0003;
}

.btn:active {
  transition-duration: 0s;
}

.active > .btn[data-toggle], .btn.active {
  z-index: 1;
  perspective: 500px;
}

.btn .caret {
  transform-style: preserve-3d;
}

.active[data-dropdown-state="open"] > .btn .caret {
  transform: rotateX(180deg);
}

.btn .petalicon {
  line-height: 0;
}

.btn.compact {
  padding: 5px 15px;
  line-height: 1;
}

.btn.whitetext:not(.disabled) {
  color: #fff !important;
}

.btn.disabled, .btn:disabled, :disabled + label .btn, fieldset[disabled] .btn {
  opacity: .5;
  cursor: default !important;
  box-shadow: none !important;
}

.btn-group {
  margin-top: 5px;
  margin-bottom: 5px;
  position: relative;
}

.btn-group:after, .btn-group:before {
  content: " ";
  display: table;
}

.btn-group .btn-group {
  float: left;
}

.btn-group-segmented .btn + .btn, .btn-group-segmented label ~ label .btn {
  margin-left: -2px;
}

.btn-group .btn {
  float: left;
  z-index: auto;
}

.btn-group .btn:active {
  z-index: 1;
}

.btn-group-segmented {
  transition: opacity .2s;
}

.btn-group-segmented .btn {
  z-index: auto;
}

.btn-group-segmented .btn.active {
  z-index: 1;
}

.btn-group-segmented label .btn, .selecter {
  z-index: auto;
}

.btn-group-segmented input[type="radio"] {
  opacity: 0;
  width: 0;
  height: 0;
  position: absolute;
  overflow: hidden;
}

.btn-group-segmented :checked + label .btn {
  z-index: 1;
}

.btn-group-segmented.disabled, fieldset[disabled] .btn-group-segmented {
  opacity: .5;
  pointer-events: none;
}

.btn-group-segmented.disabled .btn, fieldset[disabled] .btn-group-segmented .btn {
  opacity: 1;
}

.btn-group ul.dropdown-menu {
  z-index: 1000;
  float: left;
  color: #333;
  background-color: #fff;
  margin: 0;
  padding: 0;
  font-size: 1em;
  list-style: none;
  display: none;
  position: absolute;
  top: 100%;
  box-shadow: 0 5px 15px #0003;
}

.btn-group ul.dropdown-menu[data-dropdown-align="right"] {
  right: 0;
}

.btn-group ul.dropdown-menu li {
  min-width: 180px;
  margin: 0;
  padding: 0;
}

.btn-group ul.dropdown-menu li a {
  color: inherit;
  width: 100%;
  padding: 7px 20px;
  font-size: .9em;
  display: block;
}

.btn-group ul.dropdown-menu li:hover > a {
  background: #0000001a;
  text-decoration: none;
}

.btn-group ul.dropdown-menu li.divider {
  border-bottom: 1px solid #0000001a;
  height: 5px;
  margin-bottom: 5px;
  padding-bottom: 5px;
}

.btn-group ul.dropdown-menu > .submenu {
  width: 100%;
  position: relative;
}

.btn-group ul.dropdown-menu > .submenu > a {
  position: relative;
}

.btn-group ul.dropdown-menu > .submenu > a:after {
  content: "";
  border-top: 4px solid #0000;
  border-bottom: 4px solid #0000;
  border-left: 4px solid #333;
  width: 0;
  height: 0;
  margin-top: -4px;
  transition: transform .2s;
  display: inline-block;
  position: absolute;
  top: 50%;
  bottom: 2px;
  right: 10px;
}

.btn-group ul.dropdown-menu > .submenu ul.dropdown-menu {
  display: none;
  top: 0;
  left: 100%;
}

.btn-group ul.dropdown-menu > .submenu:hover > a:after {
  transform: translateX(2px);
}

.btn-group ul.dropdown-menu > .submenu:hover ul.dropdown-menu, .btn-group[data-dropdown-state="open"] > ul.dropdown-menu {
  display: block;
}

.btn-group.btn-split .btn + .btn.dropdown {
  border-left-width: 0;
  margin-left: 0;
  padding-left: 7px;
  padding-right: 7px;
  position: relative;
}

.btn-group.btn-split .btn + .btn.dropdown:not(.hollow):before {
  content: " ";
  opacity: 1;
  z-index: 0;
  background: #0000001a;
  width: 2px;
  margin: -2px 0;
  transition: opacity .15s;
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: -2px;
}

.btn-group.btn-split .btn + .btn.dropdown:active {
  border-left-width: 2px;
  margin-left: -2px;
  transition-duration: 0s;
}

.dark .btn-group.btn-split .btn + .btn.dropdown:not(.hollow):before {
  background: #ffffff4d;
}

.btn-group.btn-split.active .btn + .btn.dropdown {
  border-left-width: 2px;
  margin-left: -2px;
}

.btn-group.btn-split .btn + .btn.dropdown:active:before, .btn-group.btn-split .btn:hover + .btn.dropdown:before, .btn-group.btn-split.active .btn + .btn.dropdown:before {
  opacity: 0;
}

.btn-group.btn-split .btn + .btn.dropdown:active:before {
  transition-duration: 0s;
}

.btn-group.btn-split.active .btn + .btn.dropdown:before {
  transition-duration: .15s;
}

.btn-group.btn-split.active .btn + .btn.dropdown:active:before {
  transition-duration: 0s !important;
}

.btn.btn-spinner i.petalicon, .btn.btn-spinner:before {
  transition-duration: .2s;
  transition-timing-function: ease-out;
}

.btn.btn-spinner {
  transition-property: background, border-color, color, box-shadow, opacity, padding;
  position: relative;
}

.btn.btn-spinner:before, .form-group.validation.validation-icons .input-wrap:after {
  content: "";
  vertical-align: middle;
  height: 16px;
  position: absolute;
  top: 50%;
}

.btn.btn-spinner i.petalicon {
  transition-property: transform, opacity;
}

.btn.btn-spinner:before {
  opacity: 0;
  width: 16px;
  margin-top: -8px;
  line-height: 1;
  transition-property: transform, width, opacity;
  display: inline-block;
}

.btn.btn-spinner.loading i.petalicon {
  opacity: 0;
}

.btn.btn-spinner.loading:before {
  opacity: 1;
  background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D'http%3A//www.w3.org/2000/svg'%20viewBox%3D'0%200%2016%2016'%3E%3Cpath%20fill%3D'%23fff'%20opacity%3D'.3'%20d%3D'M8%200C3.6%200%200%203.6%200%208s3.6%208%208%208%208-3.6%208-8-3.6-8-8-8zm0%2014c-3.3%200-6-2.7-6-6s2.7-6%206-6%206%202.7%206%206-2.7%206-6%206z'/%3E%3Cpath%20fill%3D'%23fff'%20d%3D'M8%202c3.3%200%206%202.7%206%206h2c0-4.4-3.6-8-8-8v2z'/%3E%3C/svg%3E");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  animation: .4s linear infinite spinner-rotate;
}

.btn.btn-spinner.loading.white:before {
  background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D'http%3A//www.w3.org/2000/svg'%20viewBox%3D'0%200%2016%2016'%3E%3Cpath%20fill%3D'%23000'%20opacity%3D'.3'%20d%3D'M8%200C3.6%200%200%203.6%200%208s3.6%208%208%208%208-3.6%208-8-3.6-8-8-8zm0%2014c-3.3%200-6-2.7-6-6s2.7-6%206-6%206%202.7%206%206-2.7%206-6%206z'/%3E%3Cpath%20fill%3D'%23000'%20d%3D'M8%202c3.3%200%206%202.7%206%206h2c0-4.4-3.6-8-8-8v2z'/%3E%3C/svg%3E");
}

.btn.btn-spinner.loading-done i.petalicon {
  opacity: 0;
}

.btn.btn-spinner.loading-done:before {
  text-transform: none;
  content: "";
  font-size: 16px;
  animation: .2s ease-out forwards check-whop;
}

.btn.btn-spinner:not(.icon-right) i.petalicon {
  margin-right: 3px;
}

.btn.btn-spinner:not(.icon-right):before {
  left: 20px;
}

.btn.btn-spinner:not(.icon-right).compact:before {
  left: 15px;
}

.btn.btn-spinner:not(.icon-right).loading, .btn.btn-spinner:not(.icon-right).loading-done {
  pointer-events: none;
  padding-left: 45px;
}

.btn.btn-spinner:not(.icon-right).loading-done.compact, .btn.btn-spinner:not(.icon-right).loading.compact {
  padding-left: 40px;
}

.btn.btn-spinner.icon-right i.petalicon {
  margin-left: 3px;
}

.btn.btn-spinner.icon-right:before {
  right: 22px;
}

.btn.btn-spinner.icon-right.compact:before {
  right: 17px;
}

.btn.btn-spinner.icon-right.loading, .btn.btn-spinner.icon-right.loading-done {
  pointer-events: none;
  padding-right: 45px;
}

.btn.btn-spinner.icon-right.loading-done.compact, .btn.btn-spinner.icon-right.loading.compact {
  padding-right: 40px;
}

.btn.btn-spinner.has-icon:not(.icon-right).loading, .btn.btn-spinner.has-icon:not(.icon-right).loading-done {
  padding-left: 20px;
}

.btn.btn-spinner.has-icon:not(.icon-right).loading-done.compact, .btn.btn-spinner.has-icon:not(.icon-right).loading.compact {
  padding-left: 15px;
}

.btn.btn-spinner.has-icon.icon-right.loading, .btn.btn-spinner.has-icon.icon-right.loading-done {
  padding-right: 20px;
}

.btn.btn-spinner.has-icon.icon-right.loading-done.compact, .btn.btn-spinner.has-icon.icon-right.loading.compact {
  padding-right: 15px;
}

.btn {
  color: #fff;
  background-color: #30a4dc;
}

.btn.active, .btn.hollow.active, :checked + label .btn, :checked + label .btn.hollow {
  color: #fff;
  background-color: #69bde6;
  border-color: #257da7;
}

.btn.active:hover, .btn.hollow.active:hover, :checked + label .btn.hollow:hover, :checked + label .btn:hover {
  color: #fff;
}

.btn.active:focus, .btn.hollow.active:focus, :checked + label .btn.hollow:focus, :checked + label .btn:focus {
  color: #fff;
  border-color: #2a91c2;
  box-shadow: 0 0 2px #0000004d;
}

.btn:hover {
  background-color: #69bde6;
  border-color: #2a91c2;
}

.btn:active, .btn:focus {
  border-color: #257da7;
}

.btn:active {
  background-color: #2a91c2;
}

.btn .caret {
  border-top-color: #fff;
}

.btn.disabled, .btn:disabled, :disabled + label .btn, fieldset[disabled] .btn {
  color: #fff !important;
  background-color: #30a4dc !important;
  border-color: #0000 !important;
}

.dark .btn.active {
  color: #fff;
  background-color: #2a91c2;
  border-color: #83c8ea;
}

.dark .btn.active:hover {
  background-color: #30a4dc;
  border-color: #83c8ea;
}

.dark .btn.active:focus {
  color: #fff;
  border-color: #2a91c2;
  box-shadow: 0 0 2px #0000004d;
}

.dark .btn:hover {
  background-color: #2a91c2;
  border-color: #69bde6;
}

.dark .btn:focus {
  border-color: #83c8ea;
}

.dark .btn:active {
  background-color: #257da7;
  border-color: #30a4dc;
}

.dark :checked + label .btn, .dark :checked + label .btn.hollow {
  color: #fff;
  background-color: #2a91c2;
  border-color: #83c8ea;
}

.dark :checked + label .btn.hollow:hover, .dark :checked + label .btn:hover {
  background-color: #30a4dc;
  border-color: #83c8ea;
}

.btn.hollow {
  color: #30a4dc;
  background: none;
  border-color: #30a4dc;
}

.btn.hollow .caret {
  border-top-color: #30a4dc;
}

.btn.hollow:hover {
  color: #fff;
  background-color: #30a4dc;
  border-color: #30a4dc;
}

.btn.hollow:focus {
  color: #257da7;
  border-color: #257da7;
}

.btn.hollow:focus:hover {
  color: #fff;
}

.btn.hollow:active {
  color: #fff;
  background-color: #2a91c2;
  border-color: #257da7;
}

.dark .btn.hollow:focus {
  color: #69bde6;
  border-color: #69bde6;
}

.dark .btn.hollow:focus:hover {
  color: #fff;
}

.dark .btn.hollow:active {
  border-color: #69bde6;
}

.active > .btn.hollow[data-toggle="dropdown"] .caret, .btn.hollow.active .caret, .btn.hollow:active .caret, .btn.hollow:hover .caret {
  border-top-color: #fff;
}

.btn.hollow.disabled, .btn.hollow:disabled, :disabled + label .btn.hollow, fieldset[disabled] .btn.hollow {
  color: #30a4dc !important;
  background: none !important;
  border-color: #30a4dc !important;
}

.active > .btn[data-toggle="dropdown"], .active > .btn[data-toggle="dropdown"]:active, .active > .btn[data-toggle="dropdown"]:focus, .active > .btn[data-toggle="dropdown"]:hover {
  color: #fff;
  background-color: #257da7;
  border-color: #257da7;
}

.btn + ul.dropdown-menu, .btn + ul.dropdown-menu ul.dropdown-menu {
  color: #fff;
  background: #2a91c2;
}

.btn + ul.dropdown-menu li:hover > a, .btn + ul.dropdown-menu ul.dropdown-menu li:hover > a {
  background-color: #69bde680;
}

.btn + ul.dropdown-menu li.divider, .btn + ul.dropdown-menu ul.dropdown-menu li.divider {
  border-bottom-color: #fff3;
}

.btn + ul.dropdown-menu ul.dropdown-menu > .submenu > a:after, .btn + ul.dropdown-menu > .submenu > a:after {
  border-left-color: #fff;
}

.btn.red {
  color: #fff;
  background-color: #f05a67;
}

.btn.red.active, .btn.red.hollow.active, :checked + label .btn.red, :checked + label .btn.red.hollow {
  color: #fff;
  background-color: #f48791;
  border-color: #b7444e;
}

.btn.red.active:hover, .btn.red.hollow.active:hover, :checked + label .btn.red.hollow:hover, :checked + label .btn.red:hover {
  color: #fff;
}

.btn.red.active:focus, .btn.red.hollow.active:focus, :checked + label .btn.red.hollow:focus, :checked + label .btn.red:focus {
  color: #fff;
  border-color: #d44f5b;
  box-shadow: 0 0 2px #0000004d;
}

.btn.red:hover {
  background-color: #f48791;
  border-color: #d44f5b;
}

.btn.red:active, .btn.red:focus {
  border-color: #b7444e;
}

.btn.red:active {
  background-color: #d44f5b;
}

.btn.red .caret {
  border-top-color: #fff;
}

.btn.red.disabled, .btn.red:disabled, :disabled + label .btn.red, fieldset[disabled] .btn.red {
  color: #fff !important;
  background-color: #f05a67 !important;
  border-color: #0000 !important;
}

.dark .btn.red.active {
  color: #fff;
  background-color: #d44f5b;
  border-color: #f69ca4;
}

.dark .btn.red.active:hover {
  background-color: #f05a67;
  border-color: #f69ca4;
}

.dark .btn.red.active:focus {
  color: #fff;
  border-color: #d44f5b;
  box-shadow: 0 0 2px #0000004d;
}

.dark .btn.red:hover {
  background-color: #d44f5b;
  border-color: #f48791;
}

.dark .btn.red:focus {
  border-color: #f69ca4;
}

.dark .btn.red:active {
  background-color: #b7444e;
  border-color: #f05a67;
}

.dark :checked + label .btn.red, .dark :checked + label .btn.red.hollow {
  color: #fff;
  background-color: #d44f5b;
  border-color: #f69ca4;
}

.dark :checked + label .btn.red.hollow:hover, .dark :checked + label .btn.red:hover {
  background-color: #f05a67;
  border-color: #f69ca4;
}

.btn.red.hollow {
  color: #f05a67;
  background: none;
  border-color: #f05a67;
}

.btn.red.hollow .caret {
  border-top-color: #f05a67;
}

.btn.red.hollow:hover {
  color: #fff;
  background-color: #f05a67;
  border-color: #f05a67;
}

.btn.red.hollow:focus {
  color: #b7444e;
  border-color: #b7444e;
}

.btn.red.hollow:focus:hover {
  color: #fff;
}

.btn.red.hollow:active {
  color: #fff;
  background-color: #d44f5b;
  border-color: #b7444e;
}

.dark .btn.red.hollow:focus {
  color: #f48791;
  border-color: #f48791;
}

.dark .btn.red.hollow:focus:hover {
  color: #fff;
}

.dark .btn.red.hollow:active {
  border-color: #f48791;
}

.active > .btn.red.hollow[data-toggle="dropdown"] .caret, .btn.red.hollow.active .caret, .btn.red.hollow:active .caret, .btn.red.hollow:hover .caret {
  border-top-color: #fff;
}

.btn.red.hollow.disabled, .btn.red.hollow:disabled, :disabled + label .btn.red.hollow, fieldset[disabled] .btn.red.hollow {
  color: #f05a67 !important;
  background: none !important;
  border-color: #f05a67 !important;
}

.active > .btn.red[data-toggle="dropdown"], .active > .btn.red[data-toggle="dropdown"]:active, .active > .btn.red[data-toggle="dropdown"]:focus, .active > .btn.red[data-toggle="dropdown"]:hover {
  color: #fff;
  background-color: #b7444e;
  border-color: #b7444e;
}

.btn.red + ul.dropdown-menu, .btn.red + ul.dropdown-menu ul.dropdown-menu {
  color: #fff;
  background: #d44f5b;
}

.btn.red + ul.dropdown-menu li:hover > a, .btn.red + ul.dropdown-menu ul.dropdown-menu li:hover > a {
  background-color: #f4879180;
}

.btn.red + ul.dropdown-menu li.divider, .btn.red + ul.dropdown-menu ul.dropdown-menu li.divider {
  border-bottom-color: #fff3;
}

.btn.red + ul.dropdown-menu ul.dropdown-menu > .submenu > a:after, .btn.red + ul.dropdown-menu > .submenu > a:after {
  border-left-color: #fff;
}

.btn.green {
  color: #fff;
  background-color: #b2cf65;
}

.btn.green.active, .btn.green.hollow.active, :checked + label .btn.green, :checked + label .btn.green.hollow {
  color: #fff;
  background-color: #c7dc8f;
  border-color: #879d4d;
}

.btn.green.active:hover, .btn.green.hollow.active:hover, :checked + label .btn.green.hollow:hover, :checked + label .btn.green:hover {
  color: #fff;
}

.btn.green.active:focus, .btn.green.hollow.active:focus, :checked + label .btn.green.hollow:focus, :checked + label .btn.green:focus {
  color: #fff;
  border-color: #9db759;
  box-shadow: 0 0 2px #0000004d;
}

.btn.green:hover {
  background-color: #c7dc8f;
  border-color: #9db759;
}

.btn.green:active, .btn.green:focus {
  border-color: #879d4d;
}

.btn.green:active {
  background-color: #9db759;
}

.btn.green .caret {
  border-top-color: #fff;
}

.btn.green.disabled, .btn.green:disabled, :disabled + label .btn.green, fieldset[disabled] .btn.green {
  color: #fff !important;
  background-color: #b2cf65 !important;
  border-color: #0000 !important;
}

.dark .btn.green.active {
  color: #fff;
  background-color: #9db759;
  border-color: #d1e2a3;
}

.dark .btn.green.active:hover {
  background-color: #b2cf65;
  border-color: #d1e2a3;
}

.dark .btn.green.active:focus {
  color: #fff;
  border-color: #9db759;
  box-shadow: 0 0 2px #0000004d;
}

.dark .btn.green:hover {
  background-color: #9db759;
  border-color: #c7dc8f;
}

.dark .btn.green:focus {
  border-color: #d1e2a3;
}

.dark .btn.green:active {
  background-color: #879d4d;
  border-color: #b2cf65;
}

.dark :checked + label .btn.green, .dark :checked + label .btn.green.hollow {
  color: #fff;
  background-color: #9db759;
  border-color: #d1e2a3;
}

.dark :checked + label .btn.green.hollow:hover, .dark :checked + label .btn.green:hover {
  background-color: #b2cf65;
  border-color: #d1e2a3;
}

.btn.green.hollow {
  color: #b2cf65;
  background: none;
  border-color: #b2cf65;
}

.btn.green.hollow .caret {
  border-top-color: #b2cf65;
}

.btn.green.hollow:hover {
  color: #fff;
  background-color: #b2cf65;
  border-color: #b2cf65;
}

.btn.green.hollow:focus {
  color: #879d4d;
  border-color: #879d4d;
}

.btn.green.hollow:focus:hover {
  color: #fff;
}

.btn.green.hollow:active {
  color: #fff;
  background-color: #9db759;
  border-color: #879d4d;
}

.dark .btn.green.hollow:active, .dark .btn.green.hollow:focus, .form-group.validation.valid input.input, .form-group.validation.valid textarea.input {
  border-color: #c7dc8f;
}

.dark .btn.green.hollow:focus {
  color: #c7dc8f;
}

.dark .btn.green.hollow:focus:hover {
  color: #fff;
}

.active > .btn.green.hollow[data-toggle="dropdown"] .caret, .btn.green.hollow.active .caret, .btn.green.hollow:active .caret, .btn.green.hollow:hover .caret {
  border-top-color: #fff;
}

.btn.green.hollow.disabled, .btn.green.hollow:disabled, :disabled + label .btn.green.hollow, fieldset[disabled] .btn.green.hollow {
  color: #b2cf65 !important;
  background: none !important;
  border-color: #b2cf65 !important;
}

.active > .btn.green[data-toggle="dropdown"], .active > .btn.green[data-toggle="dropdown"]:active, .active > .btn.green[data-toggle="dropdown"]:focus, .active > .btn.green[data-toggle="dropdown"]:hover {
  color: #fff;
  background-color: #879d4d;
  border-color: #879d4d;
}

.btn.green + ul.dropdown-menu, .btn.green + ul.dropdown-menu ul.dropdown-menu {
  color: #fff;
  background: #9db759;
}

.btn.green + ul.dropdown-menu li:hover > a, .btn.green + ul.dropdown-menu ul.dropdown-menu li:hover > a {
  background-color: #c7dc8f80;
}

.btn.green + ul.dropdown-menu li.divider, .btn.green + ul.dropdown-menu ul.dropdown-menu li.divider {
  border-bottom-color: #fff3;
}

.btn.green + ul.dropdown-menu ul.dropdown-menu > .submenu > a:after, .btn.green + ul.dropdown-menu > .submenu > a:after {
  border-left-color: #fff;
}

input[type="date"], input[type="datetime"], input[type="datetime-local"], input[type="email"], input[type="month"], input[type="password"], input[type="range"], input[type="tel"], input[type="text"], input[type="time"], input[type="url"], input[type="week"], input[type="number"], input[type="search"] {
  appearance: none;
}

input.input, textarea.input {
  appearance: none;
  vertical-align: middle;
  background-color: #fff;
  border: 2px solid #b5b5b5;
  border-radius: 0;
  outline: 0;
  margin: 5px 0;
  padding: 8px 10px;
  font-size: .9em;
  line-height: 1.2;
  transition: box-shadow .15s, border-color .15s, background-color .15s, color .15s;
  box-shadow: inset 0 1px 1px #0000000d;
}

input.input:hover, textarea.input:hover {
  border-color: #9c9c9c;
}

input.input:focus, textarea.input:focus {
  border-color: #30a4dc;
  box-shadow: 0 2px 4px #0000001a;
}

.dark input.input, .dark textarea.input {
  color: #fff;
  background-color: #0006;
  border-color: #999;
}

.dark input.input:hover, .dark textarea.input:hover {
  border-color: #b5b5b5;
}

.dark input.input:focus, .dark textarea.input:focus {
  background-color: #000000b3;
  border-color: #30a4dc;
}

textarea.input {
  min-width: 250px;
  min-height: 60px;
}

textarea.input.resize-vertical {
  resize: vertical;
}

textarea.input.resize-horizontal {
  resize: horizontal;
}

.form-placeholder {
  vertical-align: middle;
  margin: 5px 0;
  padding: 10px 0;
  font-size: .9em;
  line-height: 1.2;
}

input.input:invalid, input.input:invalid:hover, textarea.input:invalid, textarea.input:invalid:hover {
  border-color: #f05a67;
}

fieldset:disabled input.input, fieldset:disabled textarea.input, input.input:disabled, textarea.input:disabled {
  opacity: .7;
  background-color: #9993;
  border-color: #b5b5b5;
}

.dark fieldset:disabled input.input, .dark fieldset:disabled textarea.input, .dark input.input:disabled, .dark textarea.input:disabled {
  border-color: #878787;
}

input.input[readonly], textarea.input[readonly] {
  background-color: #9999991a;
}

.form-label {
  letter-spacing: .1em;
  margin: 5px 0;
  padding-right: 5px;
  font-size: .8em;
  display: block;
}

.form-caption {
  color: #999999b3;
  text-transform: none;
  letter-spacing: 0;
  margin: 8px 0;
  font-size: .8em;
  font-weight: 400;
  text-decoration: none;
}

.form-label .form-caption {
  margin: 0;
  font-size: 1em;
  display: inline-block;
}

.form-group input.input, .form-group label, .form-group textarea.input {
  width: 100%;
  display: block;
}

.form-group.validation.error.validation-icons .input-wrap:after, .form-group.validation.valid.validation-icons .input-wrap:after, .form-group.validation.warning.validation-icons .input-wrap:after {
  font-variant: normal;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
}

.form-group {
  width: 100%;
  margin-bottom: 20px;
}

.form-group:last-child {
  margin-bottom: inherit;
}

.form-group .form-group {
  margin-bottom: 0;
}

.form-horizontal > .form-group {
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: stretch;
  width: auto;
  margin-left: -15px;
  margin-right: -15px;
  display: flex;
}

.form-horizontal > .form-group > [class*=" col-"]:not(:first-of-type):not(:last-of-type), .form-horizontal > .form-group > [class^="col-"]:not(:first-of-type):not(:last-of-type) {
  padding-left: 7.5px;
  padding-right: 7.5px;
}

.form-horizontal > .form-group > [class*=" col-"]:first-of-type, .form-horizontal > .form-group > [class^="col-"]:first-of-type {
  padding-right: 7.5px;
}

.form-horizontal > .form-group > [class*=" col-"]:last-of-type, .form-horizontal > .form-group > [class^="col-"]:last-of-type {
  padding-left: 7.5px;
}

.form-horizontal > .form-group .form-label {
  margin: 5px 0;
  padding: 10px 0;
}

.form-group.loading input.input, .form-group.loading textarea.input, .form-group.validation.validation-icons.error input.input, .form-group.validation.validation-icons.error textarea.input, .form-group.validation.validation-icons.valid input.input, .form-group.validation.validation-icons.valid textarea.input, .form-group.validation.validation-icons.warning input.input, .form-group.validation.validation-icons.warning textarea.input {
  padding-right: 30px;
}

.form-group.validation {
  position: relative;
}

.form-group.validation .input-wrap {
  width: 100%;
  position: relative;
}

.form-group.validation input.input {
  box-sizing: border-box;
}

.form-group.validation.validation-icons .input-wrap:after {
  opacity: 1;
  width: 16px;
  margin-top: -9px;
  animation: .2s ease-out forwards check-whop;
  display: inline-block;
  right: 10px;
}

@keyframes check-whop {
  0% {
    opacity: 0;
    transform: scale(1.4);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.form-group.validation.valid input.input:focus, .form-group.validation.valid input.input:hover, .form-group.validation.valid textarea.input:focus, .form-group.validation.valid textarea.input:hover {
  border-color: #b2cf65;
}

.form-group.validation.valid.validation-icons .input-wrap:after {
  speak: none;
  content: "";
  color: #b2cf65;
  font-family: petalicon;
}

.form-group.validation.valid .form-caption {
  color: #b2cf65;
}

.form-group.validation.warning input.input, .form-group.validation.warning textarea.input {
  border-color: #ffd478;
}

.form-group.validation.warning input.input:focus, .form-group.validation.warning input.input:hover, .form-group.validation.warning textarea.input:focus, .form-group.validation.warning textarea.input:hover {
  border-color: #ffc445;
}

.form-group.validation.warning.validation-icons .input-wrap:after {
  speak: none;
  content: "";
  color: #ffc445;
  font-family: petalicon;
}

.form-group.validation.warning .form-caption {
  color: #ffc445;
}

.form-group.validation.error input.input, .form-group.validation.error textarea.input {
  border-color: #f48791;
}

.dark input[type="checkbox"].checkbox.validation.error + label:before, .dark input[type="checkbox"].checkbox:invalid + label:before, .dark input[type="radio"].radiobox.validation.error + label:before, .dark input[type="radio"].radiobox:invalid + label:before, .form-group.validation.error input.input:focus, .form-group.validation.error input.input:hover, .form-group.validation.error textarea.input:focus, .form-group.validation.error textarea.input:hover, input[type="checkbox"].checkbox.validation.error + label:before, input[type="checkbox"].checkbox:invalid + label:before, input[type="radio"].radiobox.validation.error + label:before, input[type="radio"].radiobox:invalid + label:before {
  border-color: #f05a67;
}

.form-group.validation.error.validation-icons .input-wrap:after {
  speak: none;
  content: "";
  color: #f05a67;
  font-family: petalicon;
}

.form-group.validation.error .form-caption {
  color: #f05a67;
}

.form-group.loading {
  position: relative;
}

.form-group.loading .input-wrap {
  width: 100%;
  position: relative;
}

.form-group.loading .input-wrap:after, .form-group.loading.error .input-wrap:after, .form-group.loading.valid .input-wrap:after, .form-group.loading.warning .input-wrap:after {
  content: "";
  vertical-align: middle;
  opacity: 1;
  background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20viewBox%3D%220%200%2016%2016%22%3E%3Cpath%20fill%3D%22%2330a4dc%22%20opacity%3D%22.3%22%20d%3D%22M8%200C3.6%200%200%203.6%200%208s3.6%208%208%208%208-3.6%208-8-3.6-8-8-8zm0%2014c-3.3%200-6-2.7-6-6s2.7-6%206-6%206%202.7%206%206-2.7%206-6%206z%22/%3E%3Cpath%20fill%3D%22%2330a4dc%22%20d%3D%22M8%202c3.3%200%206%202.7%206%206h2c0-4.4-3.6-8-8-8v2z%22/%3E%3C/svg%3E");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  width: 16px;
  height: 16px;
  margin-top: -8px;
  display: inline-block;
  position: absolute;
  top: 50%;
  right: 10px;
  animation: .4s linear infinite spinner-rotate !important;
}

.input-group {
  width: 100%;
  display: flex;
}

.input-group input.input, .input-group textarea.input {
  vertical-align: middle;
  flex: 0 auto;
  margin: 0;
  display: block;
}

.input-group .input-addon, .input-group .input-addon-btn {
  vertical-align: middle;
  white-space: nowrap;
  flex: 0 auto;
  margin: 0;
  line-height: 1.2;
  display: flex;
}

.input-group .input-addon {
  background-color: #c2c2c280;
  border: 2px solid #b5b5b5;
  border-width: 2px 0;
  padding: 8px 10px;
  font-size: .9em;
}

.dark .input-group .input-addon {
  background-color: #74747480;
  border-color: #999;
}

.input-group .input-addon:first-child {
  border-left-width: 2px;
}

.input-group .input-addon:last-child {
  border-right-width: 2px;
}

.form-inline .form-group {
  vertical-align: middle;
  margin-bottom: inherit;
  width: auto;
  display: inline-block;
}

.form-inline .input-group {
  width: auto;
  display: inline-flex;
}

.form-inline input[type="checkbox"].checkbox + label, .form-inline input[type="radio"].radiobox + label {
  margin-right: 5px;
}

.form-inline input.input, .form-inline label {
  vertical-align: middle;
  width: auto;
  display: inline-block;
}

input[type="checkbox"].checkbox:checked, input[type="checkbox"].checkbox:not(:checked), input[type="radio"].radiobox:checked, input[type="radio"].radiobox:not(:checked) {
  opacity: 0;
  width: 0;
  height: 0;
  position: absolute;
}

input[type="checkbox"].checkbox + label, input[type="radio"].radiobox + label {
  cursor: pointer;
  min-height: 20px;
  margin: 5px 0;
  padding-left: 25px;
  display: block;
  position: relative;
}

input[type="checkbox"].checkbox + label:before, input[type="radio"].radiobox + label:before {
  content: "";
  box-sizing: border-box;
  background-color: #fff;
  border: 2px solid #b5b5b5;
  width: 20px;
  height: 20px;
  margin-left: -25px;
  transition: border-color .2s, background-color .2s;
  display: block;
  position: absolute;
  top: -1px;
}

input[type="checkbox"].checkbox + label:hover:before, input[type="radio"].radiobox + label:hover:before {
  border-color: #9c9c9c;
}

input[type="checkbox"].checkbox + label:active:before, input[type="radio"].radiobox + label:active:before {
  transition-duration: 0;
  background-color: #e6e6e6;
  border-color: #30a4dc;
}

.dark input[type="checkbox"].checkbox + label:before, .dark input[type="radio"].radiobox + label:before {
  background-color: #1a1a1a;
  border-color: #747474;
}

.dark input[type="checkbox"].checkbox + label:hover:before, .dark input[type="radio"].radiobox + label:hover:before {
  border-color: #8e8e8e;
}

.dark input[type="checkbox"].checkbox + label:active:before, .dark input[type="radio"].radiobox + label:active:before {
  background-color: #333;
}

input[type="checkbox"].checkbox + label:after, input[type="checkbox"].checkbox + label:before, input[type="radio"].radiobox + label:after, input[type="radio"].radiobox + label:before {
  border-radius: 100%;
}

.dark input[type="checkbox"].checkbox:focus + label:before, .dark input[type="radio"].radiobox:focus + label:before, input[type="checkbox"].checkbox:focus + label:before, input[type="radio"].radiobox:focus + label:before {
  border-color: #30a4dc;
}

input[type="checkbox"].checkbox + label:after, input[type="radio"].radiobox + label:after {
  content: "";
  box-sizing: border-box;
  background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20viewBox%3D%220%200%2016%2016%22%3E%3Cpath%20fill%3D%22none%22%20stroke%3D%22%232a91c2%22%20stroke-width%3D%222px%22%20d%3D%22M3%207l4%204%207-7%22%20vector-effect%3D%22non-scaling-stroke%22/%3E%3C/svg%3E");
  background-position: 0;
  background-size: 20px 20px;
  background-clip: content-box;
  width: 0;
  height: 20px;
  padding: 0;
  transition: width .1s, transform .1s;
  display: block;
  position: absolute;
  top: -1px;
  left: 0;
}

.dark input[type="checkbox"].checkbox + label:after, .dark input[type="radio"].radiobox + label:after {
  background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20viewBox%3D%220%200%2016%2016%22%3E%3Cpath%20fill%3D%22none%22%20stroke%3D%22%2369bde6%22%20stroke-width%3D%222px%22%20d%3D%22M3%207l4%204%207-7%22%20vector-effect%3D%22non-scaling-stroke%22/%3E%3C/svg%3E");
}

input[type="radio"].radiobox + label:after {
  background-color: #2a91c2;
  width: 20px;
  height: 20px;
  padding: 5px;
  transform: scale(0);
}

.dark input[type="radio"].radiobox + label:after {
  background-color: #69bde6;
}

input[type="checkbox"].checkbox:checked + label:after {
  width: 20px;
}

input[type="radio"].radiobox:checked + label:after {
  transform: scale(1);
  background-image: none !important;
}

fieldset:disabled input[type="checkbox"].checkbox + label:before, fieldset:disabled input[type="checkbox"].checkbox + label:hover:before, fieldset:disabled input[type="radio"].radiobox + label:before, fieldset:disabled input[type="radio"].radiobox + label:hover:before, input[type="checkbox"].checkbox:disabled + label:before, input[type="checkbox"].checkbox:disabled + label:hover:before, input[type="radio"].radiobox:disabled + label:before, input[type="radio"].radiobox:disabled + label:hover:before {
  background-color: #9996;
  border-color: #b5b5b580;
}

fieldset:disabled input[type="checkbox"].checkbox + label:after, fieldset:disabled input[type="radio"].radiobox + label:after, input[type="checkbox"].checkbox:disabled + label:after, input[type="radio"].radiobox:disabled + label:after {
  opacity: .5;
}

.dark fieldset:disabled input[type="checkbox"].checkbox + label:before, .dark fieldset:disabled input[type="checkbox"].checkbox + label:hover:before, .dark fieldset:disabled input[type="radio"].radiobox + label:before, .dark fieldset:disabled input[type="radio"].radiobox + label:hover:before, .dark input[type="checkbox"].checkbox:disabled + label:before, .dark input[type="checkbox"].checkbox:disabled + label:hover:before, .dark input[type="radio"].radiobox:disabled + label:before, .dark input[type="radio"].radiobox:disabled + label:hover:before {
  background-color: #74747466;
  border-color: #99999980;
}

.chk-switch-wrap {
  align-items: center;
  display: flex;
}

.chk-switch, .chk-switch-label {
  display: inline-block;
}

.chk-switch-label + .chk-switch {
  vertical-align: middle;
  margin: 0 3px;
}

.chk-switch {
  text-align: left;
  position: relative;
}

.chk-switch input[type="checkbox"]:checked, .chk-switch input[type="checkbox"]:not(:checked) {
  opacity: 0;
  width: 0;
  height: 0;
  position: absolute;
}

.chk-switch label {
  box-sizing: content-box;
  cursor: pointer;
  background-color: #fff;
  border: 2px solid #b5b5b5;
  border-radius: 20px;
  width: 44px;
  height: 20px;
  margin: 0;
  padding: 0;
  transition-property: border-color, background-color;
  transition-duration: .2s;
  display: block;
  position: relative;
  overflow: hidden;
}

.chk-switch label span, .chk-switch label span:after, .chk-switch label span:before {
  border-radius: 20px;
  height: 16px;
  position: absolute;
}

.chk-switch label:hover {
  border-color: #9c9c9c;
}

.chk-switch label:active {
  transition-duration: 0;
  background-color: #c2c2c2;
  border-color: #30a4dc;
}

.dark .chk-switch label {
  background-color: #111;
  border-color: #747474;
}

.dark .chk-switch label:hover {
  border-color: #8e8e8e;
}

.dark .chk-switch label:active {
  background-color: #000;
  border-color: #30a4dc;
}

.chk-switch input[type="checkbox"]:focus + label {
  border-color: #30a4dc;
  box-shadow: 0 1px 2px #0000001a;
}

.chk-switch label span {
  width: 40px;
  margin: 2px;
  overflow: hidden;
  -webkit-transform: translate3d(0, 0, 0);
}

.chk-switch label span:before {
  content: "";
  background-clip: content-box;
  width: 40px;
  transition: transform .1s, background-color .1s;
  display: block;
  top: 0;
  left: 0;
  transform: translate(-24px);
}

.chk-switch label span:after {
  content: "";
  background-color: #878787;
  width: 16px;
  transition: transform .1s;
  top: 0;
  right: 0;
  transform: translate(-24px);
}

.selecter .selecter-selected, select.select {
  color: inherit;
  text-overflow: clip;
  cursor: pointer;
  margin: 0;
  font-size: .9em;
  font-weight: 700;
  transition: border-color .15s, background-color .15s, box-shadow .15s, color .15s;
  display: block;
  position: relative;
}

.dark .chk-switch label span:after {
  background-color: #b5b5b5;
}

.chk-switch:not(.ab-type) label span:before {
  background-color: #99999980;
}

.chk-switch:not(.ab-type) label:hover span:before {
  background-color: #9999;
}

.chk-switch input[type="checkbox"]:checked + label span:after, .chk-switch input[type="checkbox"]:checked + label span:before {
  transform: translate(0);
}

.chk-switch.onoff-type label span:before {
  background-color: #b2cf65b3;
}

.chk-switch.onoff-type label:hover span:before {
  background-color: #b2cf65cc;
}

.chk-switch.onoff-type label span:after {
  background-color: #878787;
}

.chk-switch.onoff-type input[type="checkbox"]:checked + label span:after {
  background-color: #879d4d;
}

.dark .chk-switch.onoff-type label span:after {
  background-color: #b5b5b5;
}

.dark .chk-switch.onoff-type input[type="checkbox"]:checked + label span:after {
  background-color: #c7dc8f;
}

.chk-switch.onoff-type.led label span:after {
  box-sizing: border-box;
  border: 6px solid #878787;
  background-color: red !important;
}

.dark .chk-switch.onoff-type.led label span:after {
  border-color: #b5b5b5;
}

.chk-switch.onoff-type.led input[type="checkbox"]:checked + label span:after {
  box-shadow: 0 0 2px #556330b3;
  background-color: #d7ff6c !important;
}

.chk-switch.disabled, .chk-switch.disabled label, fieldset:disabled .chk-switch, fieldset:disabled .chk-switch label {
  pointer-events: none;
}

.chk-switch.disabled label, .chk-switch.disabled label:active, .chk-switch.disabled label:hover, fieldset:disabled .chk-switch label, fieldset:disabled .chk-switch label:active, fieldset:disabled .chk-switch label:hover {
  background-color: #9993;
  border-color: #b5b5b580;
}

.chk-switch fieldset:disabled input[type="checkbox"] + label, .chk-switch input[type="checkbox"]:disabled + label {
  pointer-events: none;
}

.chk-switch fieldset:disabled input[type="checkbox"] + label span, .chk-switch input[type="checkbox"]:disabled + label span {
  opacity: .5;
  pointer-events: none;
}

.chk-switch fieldset:disabled input[type="checkbox"] + label span:before, .chk-switch input[type="checkbox"]:disabled + label span:before {
  border-color: #b5b5b580;
}

.dark .chk-switch fieldset:disabled input[type="checkbox"] + label span:before, .dark .chk-switch fieldset:disabled input[type="checkbox"] + label span:hover:before, .dark .chk-switch input[type="checkbox"]:disabled + label span:before, .dark .chk-switch input[type="checkbox"]:disabled + label span:hover:before {
  border-color: #99999980;
}

.chk-switch.align-right {
  float: right;
}

select.select {
  appearance: none;
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D'http%3A//www.w3.org/2000/svg'%20width%3D'30'%20height%3D'24'%3E%3Cpath%20fill%3D'%23aaa'%20d%3D'M8%2011l4%204%204-4z'/%3E%3C/svg%3E");
  background-position: 100%;
  background-repeat: no-repeat;
  border: 2px solid #b5b5b5;
  border-radius: 0;
  padding: 8px 40px 8px 20px;
}

select.select:hover {
  background-color: #f2f2f2;
  border-color: #9c9c9c;
  box-shadow: 0 2px 4px -2px #0003;
}

select.select:focus {
  background-color: #f2f2f2;
  border-color: #30a4dc;
  box-shadow: 0 2px 4px #0000001a;
  outline: 0 !important;
}

select.select:disabled {
  opacity: .7;
  color: #999;
  pointer-events: none;
  cursor: default;
  box-shadow: none;
  background-color: #fff;
  border-color: #b5b5b5 !important;
}

select.select[multiple] {
  background-image: none;
  padding: 0;
}

select.select[multiple]:hover {
  background-color: #fff;
}

select.select[multiple] option {
  padding: 8px 40px 8px 20px;
}

select.select.stretch {
  width: 100%;
}

.dark select.select {
  color: #fff;
  background-color: #000;
}

.dark select.select:focus, .dark select.select:hover {
  background-color: #0d0d0d;
}

.dark select.select[multiple]:hover {
  background-color: #000;
}

.selecter {
  margin: 5px 0;
  display: block;
  position: relative;
}

.selecter:focus {
  box-shadow: none;
  outline: 0;
}

.selecter, .selecter * {
  -webkit-user-select: none !important;
  user-select: none !important;
}

.selecter .selecter-element {
  opacity: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
}

.selecter .selecter-element, .selecter .selecter-element:focus {
  -webkit-tap-highlight-color: #fff0;
  -webkit-tap-highlight-color: transparent;
  outline: 0;
}

.selecter .selecter-selected {
  background-color: #fff;
  border: 2px solid #b5b5b5;
  padding: 8px 40px 8px 20px;
  overflow: hidden;
}

.selecter .selecter-selected:after {
  content: "";
  transform-style: preserve-3d;
  border-top: 4px solid #999;
  border-left: 4px solid #0000;
  border-right: 4px solid #0000;
  width: 0;
  height: 0;
  margin: auto 0;
  transition: border-color .2s, transform .3s;
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 15px;
}

.selecter .selecter-options {
  z-index: 50;
  background-color: #fff;
  border: none;
  width: 100%;
  max-height: 250px;
  margin: 5px 0;
  padding: 0;
  transition: border-color .15s, background-color .15s, box-shadow .15s, color .15s;
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  overflow-x: hidden;
  overflow-y: auto;
  box-shadow: 0 5px 15px #0003, 0 0 1px #0000001a;
}

.selecter .selecter-options .selecter-group {
  color: #999;
  text-transform: uppercase;
  margin: 5px 0 0;
  padding: 5px 20px 5px 15px;
  font-size: .8em;
  font-weight: 700;
  display: block;
}

.selecter .selecter-options .selecter-group:first-child {
  margin-top: 0;
}

.selecter .selecter-options .selecter-item {
  cursor: pointer;
  text-overflow: ellipsis;
  background-color: #0000;
  width: 100%;
  margin: 0 0 1px;
  padding: 8px 22px;
  font-size: .9em;
  text-decoration: none;
  display: block;
  overflow: hidden;
}

.selecter .selecter-options .selecter-item.placeholder {
  display: none;
}

.selecter .selecter-options .selecter-item:last-child {
  margin-bottom: 0;
}

.selecter .selecter-options .selecter-item:hover {
  background-color: #0000000d;
}

.selecter .selecter-options .selecter-item:active {
  color: #fff;
  background-color: #30a4dc;
}

.selecter .selecter-options .selecter-item.selected {
  padding-right: 42px;
  font-weight: 700;
  position: relative;
}

.selecter .selecter-options .selecter-item.selected:hover {
  color: #fff;
  background-color: #30a4dccc;
}

.selecter .selecter-options .selecter-item.selected:after {
  content: "";
  background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20viewBox%3D%220%200%2016%2016%22%3E%3Cpolygon%20fill%3D%22%2330a4dc%22%20points%3D%2213.7,5.7%2012.2,4.3%206.6,9.9%203.8,7.1%202.3,8.5%206.6,12.7%20%22/%3E%3C/svg%3E");
  width: 16px;
  height: 16px;
  display: inline-block;
  position: absolute;
  top: 8px;
  right: 20px;
}

.selecter.multiple .selecter-options .selecter-item.selected:after, .selecter:not(.multiple) .selecter-options:not(:active) .selecter-item.selected:after {
  background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20viewBox%3D%220%200%2016%2016%22%3E%3Cpolygon%20fill%3D%22%23ffffff%22%20points%3D%2213.7,5.7%2012.2,4.3%206.6,9.9%203.8,7.1%202.3,8.5%206.6,12.7%20%22/%3E%3C/svg%3E");
}

.selecter:not(.multiple) .selecter-options:not(:active) .selecter-item.selected {
  color: #fff;
  background-color: #30a4dc;
}

.selecter:not(.multiple) .selecter-options:not(:active) .selecter-item.selected:hover {
  background-color: #30a4dccc;
}

.selecter.multiple .selecter-options {
  background-color: #fff;
}

.selecter.multiple .selecter-options .selecter-item.selected {
  color: #fff;
  background-color: #30a4dc;
}

.selecter.multiple .selecter-options .selecter-item.selected:hover {
  background-color: #30a4dccc;
}

.selecter .selecter-options .selecter-item.disabled, .selecter .selecter-options .selecter-item.disabled:hover {
  color: #b5b5b5;
  cursor: not-allowed;
}

.selecter .selecter-options .selecter-item.disabled:active {
  background-color: #0000000d;
}

.selecter:hover .selecter-selected {
  background-color: #fff;
  border-color: #9c9c9c;
  box-shadow: 0 2px 4px -2px #0003;
}

.selecter:hover .selecter-selected:after {
  border-top-color: #999;
}

.selecter.focus .selecter-selected, .selecter:focus .selecter-selected {
  background-color: #fff;
  border-color: #30a4dc;
  box-shadow: 0 2px 4px #0000001a;
}

.selecter.focus .selecter-selected:after, .selecter:focus .selecter-selected:after {
  border-top-color: #30a4dc;
}

.selecter.open {
  z-index: 3;
}

.selecter.open .selecter-selected {
  color: #fff;
  background-color: #30a4dc;
  border-color: #2a91c2;
}

.selecter.open .selecter-selected:after {
  border-top-color: #fff;
  transform: rotateX(180deg);
}

.selecter.cover .selecter-options {
  border-width: 2px;
  border-color: #30a4dc;
  margin: 0;
  top: 0;
}

.selecter.bottom .selecter-options {
  border-width: 2px 2px 0;
  top: auto;
  bottom: 100%;
}

.selecter.bottom .selecter-item:last-child {
  border: none;
}

.selecter.bottom.cover .selecter-options {
  border-width: 2px;
  top: auto;
  bottom: 0;
}

.selecter.multiple .selecter-options {
  box-shadow: none;
  border: 2px solid #b5b5b5;
  width: 100%;
  display: block;
  position: static;
}

.paging, .spinner {
  display: inline-block;
}

.selecter.multiple:hover {
  box-shadow: 0 2px 4px -2px #0003;
}

.selecter.multiple:hover .selecter-options {
  border-color: #9c9c9c;
}

.selecter.multiple.focus .selecter-options, .selecter.multiple:focus .selecter-options {
  border-color: #30a4dc;
  box-shadow: 0 2px 4px #0003;
}

.selecter.disabled {
  opacity: .7;
}

.selecter.disabled .selecter-selected, .selecter.disabled .selecter-selected:hover {
  color: #999;
  pointer-events: none;
  cursor: default;
  box-shadow: none;
  background-color: #fff;
  border-color: #b5b5b5 !important;
}

.selecter.disabled .selecter-selected:after {
  border-top-color: #999;
}

.selecter.disabled .selecter-options {
  pointer-events: none;
  box-shadow: none !important;
  border-color: #b5b5b5 !important;
}

.selecter.disabled .selecter-group, .selecter.disabled .selecter-item {
  cursor: default;
  border-color: #999;
}

.selecter.disabled .selecter-item, .selecter.disabled .selecter-item:hover {
  color: #999;
}

.selecter.disabled.multiple.focus, .selecter.disabled.multiple:focus {
  box-shadow: none !important;
}

.dark .selecter {
  color: #fff;
}

.dark .selecter .selecter-selected {
  background-color: #0006;
  border-color: #999;
}

.dark .selecter:hover .selecter-selected {
  border-color: #b5b5b5;
}

.dark .selecter:focus .selecter-selected {
  background-color: #000000b3;
  border-color: #30a4dc;
}

.dark .selecter .selecter-options {
  background-color: #212121;
  box-shadow: 0 5px 15px #0003, 0 0 1px #ffffff1a;
}

.dark .selecter.multiple .selecter-options {
  background-color: #000;
}

.dark .selecter .selecter-item:hover {
  background-color: #fff3;
}

.dark .selecter .selecter-item:active {
  background-color: #30a4dc;
}

.spinner {
  vertical-align: middle;
  width: 20px;
  height: 20px;
}

.spinner span {
  border: 2px solid #74747433;
  border-top-color: #30a4dc;
  border-radius: 40px;
  width: 20px;
  height: 20px;
  display: block;
}

.spinner.spinning span {
  animation: .6s linear infinite spinner-rotate;
}

@keyframes spinner-rotate {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

.spinner.hidden {
  display: none;
}

.spinner.small, .spinner.small span {
  width: 16px;
  height: 16px;
}

.spinner.big, .spinner.big span {
  width: 40px;
  height: 40px;
}

.spinner.big span {
  border-width: 3px;
}

table.table {
  background-color: #fff;
  width: 100%;
}

.dark table.table {
  background-color: #1a1a1a;
}

table.table caption {
  text-align: left;
  margin: 10px 0;
  font-size: .9em;
}

table.table col.fit, table.table colgroup.fit, table.table td.fit, table.table th.fit {
  width: 1%;
}

table.table td, table.table th {
  text-align: left;
  border-bottom: 1px solid #c2c2c280;
  padding: 10px;
}

.dark table.table td, .dark table.table th {
  border-bottom-color: #74747480;
}

table.table td.nowrap, table.table th.nowrap {
  white-space: nowrap;
}

table.table thead tr:last-child td, table.table thead tr:last-child th {
  border-bottom: 2px solid #c2c2c2;
}

.dark table.table thead tr:last-child td, .dark table.table thead tr:last-child th {
  border-bottom-color: #747474;
}

table.table tfoot tr:first-child td, table.table tfoot tr:first-child th {
  border-top: 2px solid #c2c2c2;
}

.dark table.table tfoot tr:first-child td, .dark table.table tfoot tr:first-child th {
  border-top-color: #747474;
}

table.table tfoot tr:last-child td, table.table tfoot tr:last-child th {
  border-bottom: none;
}

table.table.table-inner-borders td, table.table.table-inner-borders th {
  border-right: 1px solid #c2c2c2;
}

.dark table.table.table-inner-borders td, .dark table.table.table-inner-borders th {
  border-right-color: #747474;
}

table.table.table-inner-borders td:last-child, table.table.table-inner-borders th:last-child {
  border-right: none;
}

table.table.table-outer-borders {
  border: 1px solid #c2c2c2;
}

.dark table.table.table-outer-borders {
  border-color: #747474;
}

table.table.table-striped tr:nth-child(2n) {
  background-color: #f2f2f280;
}

.dark table.table.table-striped tr:nth-child(2n) {
  background-color: #26262680;
}

table.table.table-hover tbody tr:hover td, table.table.table-hover > tr:hover td {
  background-color: #f2f2f299;
}

.dark table.table.table-hover tbody tr:hover td, .dark table.table.table-hover > tr:hover td {
  background-color: #26262699;
}

table.table.table-condensed td, table.table.table-condensed th {
  padding: 7px 10px;
}

.paging {
  text-align: center;
  margin: 0;
  padding: 0;
}

.paging:after, .paging:before {
  content: " ";
  display: table;
}

.paging .pg-block, .paging .pg-list {
  float: left;
  vertical-align: middle;
  padding: 0;
  display: block;
}

.paging .pg-list {
  white-space: nowrap;
  margin: 0 auto;
  list-style: none;
}

.paging .pg-block {
  margin: 0;
}

.paging .pg-block a, .paging .pg-ellipsis {
  box-sizing: content-box;
  vertical-align: middle;
  text-align: center;
  min-height: 20px;
  padding: 8px 15px;
  overflow: hidden;
}

.paging .pg-block a {
  text-transform: uppercase;
  color: #666;
  cursor: pointer;
  background-color: #fff;
  margin: 0 2px;
  transition-property: background-color, color, transform, box-shadow;
  transition-duration: .2s;
  display: inline-block;
  box-shadow: 0 1px 4px #0000000d;
}

.paging .pg-block a:hover {
  color: #30a4dc;
  text-decoration: none;
}

.paging .pg-block a span {
  vertical-align: middle;
  display: inline-block;
}

.paging .pg-block:not(.disabled) a:hover {
  box-shadow: 0 2px 4px #0000001a;
}

.paging .pg-block:not(.disabled) a:active {
  box-shadow: 0 1px 4px #0000000d;
}

.paging .pg-block.active a {
  color: #fff;
  background-color: #69bde6;
  font-weight: 700;
}

.paging .pg-block.disabled {
  opacity: .3;
}

.paging .pg-block.disabled a {
  color: #666;
  cursor: default;
}

.paging .pg-block.next, .paging .pg-block.prev {
  min-width: 90px;
  margin: 0 10px;
}

.paging .pg-block.next .petalicon, .paging .pg-block.prev, .tab-group .tab .badge {
  margin-left: 5px;
}

.paging .pg-block .petalicon {
  transition: transform .2s;
}

.paging .pg-block.prev .petalicon {
  margin-right: 5px;
}

.paging .pg-block.next {
  float: right;
  margin-right: 5px;
}

.paging .pg-block.next .petalicon:before {
  content: "";
}

.paging .pg-block.prev:not(.disabled):hover .petalicon {
  transform: translateX(-2px);
}

.paging .pg-block.next:not(.disabled):hover .petalicon {
  transform: translateX(2px);
}

.dark .paging .pg-block a {
  color: #fff;
  background-color: #444;
}

.dark .paging .pg-block a:hover {
  color: #30a4dc;
}

.dark .paging .pg-block.active a {
  color: #fff;
  background-color: #69bde6;
}

.dark .paging .pg-block.disabled {
  opacity: .3;
}

.paging .pg-ellipsis {
  float: left;
  color: #666;
  margin: 0 2px;
  display: block;
}

.dark .paging.inline .pg-block a {
  color: #fff;
}

.paging.inline .pg-block a, .paging.inline .pg-block a:active, .paging.inline .pg-block a:hover, .paging.inline .pg-block.active a {
  box-shadow: none;
  background-color: #0000;
}

.paging.inline .pg-block.active a {
  color: #30a4dc;
}

.paging.pg-stretch {
  width: 100%;
  display: block;
}

.paging.pg-stretch .pg-list {
  float: none;
  display: inline-block;
}

.card, .panel {
  background-color: #fff;
  transition: background-color .15s, box-shadow .15s, color .15s;
  box-shadow: 0 1px 4px #0000000d;
}

.card.hover-em:hover, .panel.hover-em:hover {
  box-shadow: 0 2px 4px #0000001a;
}

.dark .card, .dark .panel {
  background-color: #494949;
  box-shadow: 0 1px 4px #0000001a;
}

.dark .card.hover-em:hover, .dark .panel.hover-em:hover {
  box-shadow: 0 2px 4px #0000001a;
}

span.badge, span.label {
  color: #fff;
  letter-spacing: 0;
  text-transform: none;
  text-align: center;
  background-color: #999;
  min-width: 1.5em;
  padding: .2em .5em;
  font-size: .8em;
  font-weight: 700;
  display: inline-block;
}

.alert, .tab-group {
  display: -ms-flexbox;
}

span.badge.accent, span.label.accent {
  background-color: #30a4dc;
}

span.badge.okay, span.label.okay {
  background-color: #b2cf65;
}

span.badge.warning, span.label.warning {
  background-color: #ffc445;
}

span.badge.danger, span.label.danger {
  background-color: #f05a67;
}

span.badge.white, span.label.white {
  color: #333;
  background-color: #fff;
}

span.badge.white.accent, span.label.white.accent {
  color: #30a4dc;
}

span.badge.white.okay, span.label.white.okay {
  color: #b2cf65;
}

span.badge.white.warning, span.label.white.warning {
  color: #ffc445;
}

span.badge.white.danger, span.label.white.danger {
  color: #f05a67;
}

span.badge {
  border-radius: 10em;
}

.tab-group {
  background-color: #b5b5b5;
  flex-flow: wrap;
  display: flex;
  position: relative;
  overflow: hidden;
}

.tab-group .tab {
  color: #fff;
  cursor: pointer;
  background-color: #999;
  margin-right: 1px;
  padding: 12px 30px;
  transition-property: background-color, color;
  transition-duration: .15s;
  display: block;
  position: relative;
}

.tab-group .tab .tab-icon .petalicon, .tab-group .tab > span {
  vertical-align: middle;
  display: inline-block;
}

.tab-group .tab.spacer {
  pointer-events: none;
  cursor: default;
  flex: 1 1 0;
  margin-right: 0;
  padding: 0;
}

.tab-group .tab > span {
  z-index: 1;
  position: relative;
}

.tab-group .tab .tab-icon {
  margin-right: 3px;
}

.tab-group .tab .badge:empty {
  margin-left: 0;
  display: none;
}

.tab-group.tab-style-01 .tab:before, .tab-group.tab-style-02 .tab:before {
  content: "";
  transition-duration: .15s;
  display: block;
  position: absolute;
}

.tab-group .tab:hover {
  background-color: #878787;
}

.tab-group .tab:active {
  background-color: #747474;
}

.tab-group input[type="radio"] {
  opacity: 0;
  width: 0;
  height: 0;
  position: absolute;
  overflow: hidden;
}

.tab-group .tab.selected, .tab-group :checked + label .tab {
  background-color: #30a4dc;
}

.tab-group .tab.disabled, .tab-group :disabled + label .tab {
  color: #b5b5b5;
  pointer-events: none;
}

.tab-group .tab-content {
  flex-basis: 100%;
}

.tab-group.compact .tab {
  padding: 5px 15px;
}

.dark .tab-group {
  background-color: #747474;
}

.dark .tab-group .tab {
  background-color: #5d5d5d;
}

.dark .tab-group .tab.selected, .dark .tab-group :checked + label .tab {
  background-color: #30a4dc;
}

.dark .tab-group .tab.disabled, .dark .tab-group :disabled + label .tab {
  color: #878787;
}

.tab-group.tab-style-01 {
  background-color: #fff;
  overflow: hidden;
}

.tab-group.tab-style-01 .tab {
  color: #000;
  background-color: #0000;
  padding-top: 10px;
  padding-bottom: 14px;
}

.tab-group.tab-style-01 .tab:before {
  background-color: #c2c2c2;
  height: 3px;
  transition-property: background-color;
  bottom: 0;
  left: 0;
  right: 0;
}

.tab-group.tab-style-01 .tab:hover:before {
  background-color: #999;
}

.tab-group.tab-style-01 .tab:active:before {
  background-color: #747474;
}

.tab-group.tab-style-01 .tab.selected, .tab-group.tab-style-01 :checked + label .tab {
  color: #2a91c2;
  background-color: #0000;
}

.tab-group.tab-style-01 .tab.selected:before, .tab-group.tab-style-01 :checked + label .tab:before {
  background-color: #2a91c2;
}

.tab-group.tab-style-01 .tab.disabled, .tab-group.tab-style-01 :disabled + label .tab {
  color: #b5b5b5;
}

.tab-group.tab-style-01 .tab.disabled:before, .tab-group.tab-style-01 :disabled + label .tab:before {
  background-color: #c2c2c2;
}

.tab-group.tab-style-01.compact .tab {
  padding-top: 3px;
  padding-bottom: 7px;
}

.dark .tab-group.tab-style-01 {
  background-color: #000;
}

.dark .tab-group.tab-style-01 .tab {
  color: #fff;
}

.dark .tab-group.tab-style-01 .tab:before {
  background-color: #878787;
}

.dark .tab-group.tab-style-01 .tab:hover:before {
  background-color: #b5b5b5;
}

.dark .tab-group.tab-style-01 .tab:active:before {
  background-color: #c2c2c2;
}

.dark .tab-group.tab-style-01 .tab.selected, .dark .tab-group.tab-style-01 :checked + label .tab {
  color: #69bde6;
}

.dark .tab-group.tab-style-01 .tab.selected:before, .dark .tab-group.tab-style-01 :checked + label .tab:before {
  background-color: #69bde6;
}

.dark .tab-group.tab-style-01 .tab.disabled, .dark .tab-group.tab-style-01 :disabled + label .tab {
  color: #878787;
}

.dark .tab-group.tab-style-01 .tab.disabled:before, .dark .tab-group.tab-style-01 :disabled + label .tab:before {
  background-color: #878787;
}

.tab-group.tab-style-02 {
  background-color: #fff;
  overflow: visible;
}

.tab-group.tab-style-02 .tab {
  color: #333;
  background-color: #c2c2c2;
  padding-top: 10px;
  padding-bottom: 14px;
  overflow: hidden;
}

.tab-group.tab-style-02 .tab:before {
  opacity: 1;
  z-index: 0;
  transform-origin: top;
  background-color: #fff;
  height: 100%;
  transition-property: opacity, transform;
  bottom: 3px;
  left: -5px;
  right: -5px;
  transform: scaleY(1);
}

.tab-group.tab-style-02 .tab:hover {
  background-color: #999;
}

.tab-group.tab-style-02 .tab:active {
  background-color: #747474;
}

.tab-group.tab-style-02 .tab.selected, .tab-group.tab-style-02 :checked + label .tab {
  color: #fff;
  background-color: #30a4dc;
}

.tab-group.tab-style-02 .tab.selected:before, .tab-group.tab-style-02 :checked + label .tab:before {
  opacity: 0;
  transform: scaleY(0);
}

.tab-group.tab-style-02 .tab.disabled, .tab-group.tab-style-02 :disabled + label .tab {
  color: #c2c2c2;
}

.tab-group.tab-style-02.compact .tab {
  padding-top: 3px;
  padding-bottom: 7px;
}

.dark .tab-group.tab-style-02 {
  background-color: #000;
}

.dark .tab-group.tab-style-02 .tab {
  color: #fff;
  background-color: #878787;
}

.dark .tab-group.tab-style-02 .tab:before {
  background-color: #000;
}

.dark .tab-group.tab-style-02 .tab:hover {
  background-color: #999;
}

.dark .tab-group.tab-style-02 .tab:active {
  background-color: #c2c2c2;
}

.dark .tab-group.tab-style-02 .tab.selected, .dark .tab-group.tab-style-02 :checked + label .tab {
  color: #fff;
  background-color: #30a4dc;
}

.dark .tab-group.tab-style-02 .tab.disabled, .dark .tab-group.tab-style-02 :disabled + label .tab {
  color: #878787;
}

.alert {
  background-color: #d4d4d4;
  margin: 0 0 10px;
  padding: 15px 20px;
  display: flex;
}

.alert p:last-child {
  margin-bottom: 0;
}

.alert .alert-icon {
  color: #747474;
  flex: 0 auto;
  margin-right: 7px;
}

.alert .alert-text {
  flex: auto;
}

.alert.large .alert-icon {
  margin-right: 15px;
}

.alert.large .alert-icon .petalicon {
  font-size: 32px;
  line-height: 32px;
}

.dark .alert {
  background-color: #5b5b5b;
}

.dark .alert .alert-icon {
  color: #b5b5b5;
}

.alert.accent {
  background-color: #a1d6ef;
}

.dark .alert.accent {
  background-color: #1b5e7e;
}

.alert.accent .alert-icon {
  color: #257da7;
}

.dark .alert.accent .alert-icon {
  color: #69bde6;
}

.alert.okay {
  background-color: #deebbe;
}

.dark .alert.okay {
  background-color: #6a7b3c;
}

.alert.okay .alert-icon {
  color: #879d4d;
}

.dark .alert.okay .alert-icon {
  color: #c7dc8f;
}

.alert.warning {
  background-color: #ffe7b3;
}

.dark .alert.warning {
  background-color: #9a762a;
}

.alert.warning .alert-icon {
  color: #c29534;
}

.dark .alert.warning .alert-icon {
  color: #ffd478;
}

.alert.danger {
  background-color: #f9bdc2;
}

.dark .alert.danger {
  background-color: #91373e;
}

.alert.danger .alert-icon {
  color: #b7444e;
}

.dark .alert.danger .alert-icon {
  color: #f48791;
}

.fullpage-modal, .modal {
  background-color: #fff;
  box-shadow: 0 5px 15px #0003;
}

.fullpage-modal .modal-header, .modal .modal-header {
  background-color: #efefef;
  padding: 20px 20px 10px;
}

.fullpage-modal .modal-header .modal-header-text, .modal .modal-header .modal-header-text {
  margin: 0;
}

.fullpage-modal .modal-header p.sub, .modal .modal-header p.sub {
  margin: 5px 0;
}

.fullpage-modal .modal-body, .fullpage-modal .modal-footer, .modal .modal-body, .modal .modal-footer {
  padding: 20px;
}

.fullpage-modal .btn-row, .modal .btn-row {
  border-top: 1px solid #eee;
}

.fullpage-modal .btn-row:after, .fullpage-modal .btn-row:before, .modal .btn-row:after, .modal .btn-row:before {
  content: " ";
  display: table;
}

.fullpage-modal .btn-row .error, .modal .btn-row .error {
  color: #f05a67;
  padding-left: 20px;
}

.fullpage-modal .close-btn, .modal .close-btn {
  box-sizing: content-box;
  cursor: pointer;
  padding: 7px;
  transition: opacity .2s;
}

.fullpage-modal .btn.align-right, .modal .btn.align-right {
  float: right;
}

.fullpage-modal .id-label.large, .modal .id-label.large {
  font-size: 1.2em;
}

.fullpage-modal .close-btn:before, .modal .close-btn:before {
  speak: none;
  font-variant: normal;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: petalicon;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
}

.dark .fullpage-modal, .dark .modal {
  background-color: #333;
}

.dark .fullpage-modal .modal-header, .dark .modal .modal-header {
  background-color: #444;
}

.dark .fullpage-modal .btn-row, .dark .modal .btn-row {
  border-top-color: #444;
}

.modal {
  min-width: 150px;
  min-height: 40px;
  position: absolute;
}

.modal .close-btn {
  opacity: .5;
  margin: -7px;
  position: absolute;
  top: 20px;
  right: 15px;
}

.modal .close-btn:before {
  content: "";
}

.modal .close-btn:hover {
  opacity: 1;
}

.modal.popover:after, .modal.popover:before {
  content: "";
  border-bottom: 8px solid #ccc;
  border-left: 8px solid #0000;
  border-right: 8px solid #0000;
  width: 0;
  height: 0;
  display: block;
  position: absolute;
  top: -8px;
  right: 15px;
}

.modal.popover:after {
  border-bottom-color: #fff;
  top: -6px;
}

.fullpage-modal-container {
  text-align: center;
  vertical-align: middle;
  z-index: 1000;
  width: 100%;
  height: 100%;
  display: table;
  position: fixed;
  inset: 0;
  overflow: hidden;
}

.fullpage-modal-container .fullpage-modal-inner-wrap {
  text-align: center;
  vertical-align: middle;
  width: 100%;
  height: 100%;
  display: table-cell;
}

.fullpage-modal-container .overlay {
  opacity: .3;
  background-color: #000;
  display: block;
  position: absolute;
  inset: 0;
}

.fullpage-modal {
  text-align: left;
  z-index: 1000;
  background-color: #fff;
  width: 100%;
  min-width: 320px;
  max-width: 800px;
  height: auto;
  min-height: 200px;
  margin: 20px auto;
  position: relative;
  box-shadow: 0 5px 15px #0003;
}

.fullpage-modal .close-btn {
  opacity: .5;
  width: 20px;
  height: 20px;
  margin: -7px;
  position: absolute;
  top: 20px;
  right: 15px;
}

.fullpage-modal .close-btn:before {
  content: "";
}

.fullpage-modal .close-btn:hover {
  opacity: 1;
}

/*# sourceMappingURL=index.0f68c131.css.map */
